<template>
  <job-posting-layout>
    <template #topContent>
      <loader :loading="loading" v-if="loading"/>
      <div class="stepContent tw-w-full" v-if="!loading">
        <step-count :count="7" id="stepCount"/>
        <step-title title="Salary range (optional)" id="stepTitle"/>
        <div class=" tw-flex tw-w-full  tw-flex-col-reverse lg:tw-flex-row">
          <div class="lg:tw-w-3/4">
            <p class="per">State the salary range per annum for this role.</p>
            <div class="lg:tw-w-8/12">
              <small style="color: red" v-if="showError.state">{{ showError.message }}</small>
              <div style="max-width: 514px" class="tw-mb-4">
                <h6 class="label tw-mb-2">Currency</h6>
                <v-combobox
                    v-model="currency"
                    :items="returnCurrenciesName"
                    solo
                    hide-details>
                </v-combobox>
              </div>
              <div style="max-width: 514px" class="tw-flex-col tw-flex  tw-mb-6  tw-mt-10">
                <h6 class="label tw-mb-2 ">Salary Range</h6>
<!--                <v-combobox style="max-width: 514px"-->
<!--                          v-model="salary_range"-->
<!--                          solo-->
<!--                          :items="salaryRange"-->
<!--                          hide-details-->
<!--                          id="salary_range"-->
<!--                          placeholder="Select or Type Salary Range"-->

<!--                ></v-combobox>-->
                <div class="tw-flex tw-justify-between">
                  <v-text-field
                      solo
                      id="minimumSalary"
                      type="number"
                      label="Lower"
                      placeholder="21,000"
                      v-model="minimumSalary"
                      hide-details

                  ></v-text-field>
                  <div class="tw-mx-8"></div>
                  <v-text-field
                      placeholder="25,000"
                      id="maximumSalary"

                      label="Upper"
                      type="number"
                      v-model="maximumSalary"
                      solo
                      hide-details

                  ></v-text-field>
                </div>
              </div>
              <h6 class="label tw-mb-2">Additional Information e.g. Benefits</h6>
              <div style="max-width: 514px">
                <!--            <v-textarea hide-details label="" height="160px" v-model="additional_information" solo id="info"></v-textarea>-->
                <vue-editor
                    id="info"
                    :editor-toolbar="customToolbar"
                    v-model="additional_information"
                ></vue-editor>
              </div>
            </div>
          </div>
          <div class=" tw-hidden lg:tw-flex lg:tw-w-4/12 salary-tips tw-p-3 tw-flex-row tw-items-start">
            <v-col sm="1" class="mr-1">
              <vue-feather class="mt-1" style="width: 24px; height: 24px; color: #008EEF" type="info"/>
            </v-col>
            <v-col sm="11">
              <label v-if="salaryTip.lower">
                Suggested Salary for {{ salaryTip.experience_level }} {{ salaryTip.role }} ₦{{ salaryTip.lower }} -
                ₦{{ salaryTip.upper }}
              </label>
              <label v-else>
                Salary range is optional when creating a job
              </label>
            </v-col>
          </div>
        </div>
      </div>
    </template>
    <template #bottomNav>
      <bottom-navbar @click:back="routeBack" :button-name="$route.query.edit?'Save ':'Next '"
                     @click:next="updateJobById"
                     :previous="!$route.query.edit" :loading="buttonLoading"/>
    </template>
  </job-posting-layout>
</template>

<script>

import JobPostingLayout from "@/components/jobPosting/layouts/JobPostingLayout";
import StepCount from "@/components/jobPosting/reuseables/StepCount";
import StepTitle from "@/components/jobPosting/reuseables/StepTitle";
import {VueEditor} from "vue2-editor";
import BottomNavbar from "@/components/jobPosting/reuseables/BottomNavbar";
import worldCurrencies from "../../../assets/currencies"
import {getJobById, getSalaryRange, updateJobById} from "../../../services/api/APIService"
import Loader from "../../UIComponents/reusablesIcon/Loader";
// import SuggestionView from "./SuggestionView";

export default {
  name: "StepSeven",
  components: {Loader, BottomNavbar, StepTitle, StepCount, JobPostingLayout, VueEditor},
  data() {
    return {
      currency: "",
      salary_range: '',
      minimumSalary: '',
      maximumSalary: '',
      additional_information: '',
      currencies: worldCurrencies,
      loading: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          {align: ""},
          {align: "center"},
          {align: "right"},
          {align: "justify"}
        ],
        [
          {list: "ordered"},
          {list: "bullet"},
          "link"
        ],
        [{size: ["small", false, "large", "huge"]}]
      ],
      showError: {
        message: "",
        state: false
      },
      buttonLoading: false,
      salaryTip: {}
    }
  },
  watch: {
    // 'salary_range': {
    //   handler: function (data) {
    //     if(data){
    //       if (data.includes('and')) {
    //         this.minimumSalary = data.split(' ')[0].replace(/,/, '')
    //         this.maximumSalary = '10000000'
    //       } else if (data === 'Select Range') {
    //         this.minimumSalary = ''
    //         this.maximumSalary = ''
    //         this.salary_range = 'Select Range'
    //       }
    //       else {
    //         this.minimumSalary = data.split(' - ')[0].replace(/,/, '')
    //         this.maximumSalary = data.split(' - ')[1].replace(/,/, '')
    //       }
    //     }
    //   },
    //   immediate: true
    // },
  },
  computed: {
    returnCurrenciesName() {
      let returnCurrencies = []
      this.currencies.map((currency) => {
        returnCurrencies.push(currency.name + " (" + currency.symbol_native + ") ")
      })
      return returnCurrencies;
    },
    salaryRange() {
      return [
        '30,000 - 50,000',
        '50,000 - 100,000',
        '100,000 - 150,000',
        '150,000 - 200,000',
        '200,000 - 250,000',
        '250,000 - 300,000',
        '300,000 - 350,000',
        '350,000 - 400,000',
        '400,000 - 450,000',
        '450,000 - 500,000',
        '500,000 and above',
      ];
    },
  },
  methods: {
    async getJobById() {
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      let jobData = {}
      jobData.id = job.id
      jobData.site_name = this.$siteName()
      await getJobById(jobData).then(res => {
        this.currency = res.data.currency
        this.additional_information = res.data.additional_info
        this.maximumSalary = res.data.maximum_salary
        this.minimumSalary = res.data.minimum_salary
        if(res.data.minimum_salary){
          this.salary_range = res.data.minimum_salary + ' - ' + res.data.maximum_salary
        }

      }).catch(err => {
        this.loading = false
        this.$displaySnackbar({message: err.response.data.detail, success: false})
      })
    },
    updateJobById() {
      // if(!this.maximumSalary) {
      //   this.showError.state = true
      //   this.showError.message = "Please add a maximum salary"
      //   console.log('hello')
      //
      //   return
      // }
      this.showError.state = false
      this.buttonLoading = true
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      let jobId = job.id
      let salaryDetails = {}
      salaryDetails.site_name = this.$siteName()

      if (Number.parseInt(this.minimumSalary) > Number.parseInt(this.maximumSalary)) {
        this.showError.state = true
        this.showError.message = "Minimum salary can not be greater than maximum salary"
        this.buttonLoading = false
      } else {
        this.showError.state = false
        this.showError.message = ""
        salaryDetails.maximum_salary = this.maximumSalary
        salaryDetails.minimum_salary = this.minimumSalary
        salaryDetails.currency = this.currency
        // sessionStorage.setItem('salary',JSON.stringify(salaryDetails))
        salaryDetails.additional_info = this.additional_information
        updateJobById(jobId, salaryDetails).then(() => {
          this.buttonLoading = false
          if (this.$route.query.edit) {
            this.$router.push({name: 'JobSummary'})
          } else {
            this.$router.push({name: 'Job-stepEight'})
          }
        })
            .catch(err => {
              this.buttonLoading = false
              this.loading = false
              this.$displaySnackbar({message: err.response.data.detail, success: false})
            })
      }
    },
    routeBack() {
      this.$router.push({name: 'Job-stepSix'})
    },
    async getSalaryRange() {
      this.loading = true
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      const data = {}
      data.experience_level = job.experience_level
      data.role = job.role
      this.salaryTip.experience_level = data.experience_level
      this.salaryTip.role = data.role
      await getSalaryRange(data).then(res => {
        this.loading = false
        if (res.data.salary_range) {
          this.salaryTip.lower = res.data.salary_range.lower_limit
          this.salaryTip.upper = res.data.salary_range.upper_limit
        }

      }).catch(err => {
        this.loading = false
        console.log(err.status)
        if (err.status !== 404 && err.response) {
          this.$displaySnackbar({message: err.response.data.detail, success: false})
        }
      })
    }
  },
  async created() {
    await this.getSalaryRange()
    await this.getJobById()
  }

}
</script>

<style scoped>
.per {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9CABB5;
}

.minus {
  width: 17px;
  height: 2px;
  left: 513px;
  top: 357px;
  background: #D0DCE4;

}

.salary-tips {
  background: #F7FCFF;
  border: 1px solid #45B4FF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
  max-height: 8rem;
}
</style>
